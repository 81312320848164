import { _ } from "vue-underscore";
import Multiselect from "vue-multiselect";

export default {
  data: () => ({
    content: "",
    backUrl: "/email-static-template",
    emailStaticTemplate: {
      id: 0,
      name: "",
      subject: "",
      body: "",
      group: "",
    },
    // is_client_email: false,
    config: {},
    loading: false,
  }),

  computed: {},
  components: { Multiselect },

  methods: {
    formatString(input) {
      // Split the string by underscore, capitalize each word, and join them back with a space
      return input
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    validateEmailStaticTemplate() {
      this.emailStaticTemplate.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },
    update() {
      let _vm = this;
      this.axios
        .post("/email-static-templates/" + this.emailStaticTemplate.id, {
          ...this.emailStaticTemplate,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.emailStaticTemplate = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () { });
    },
    add() {
      let _vm = this;
      this.axios
        .post("/email-static-templates", _vm.emailStaticTemplate)
        .then(function (response) {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () { });
    },

    getDetail() {
      let _vm = this;
      this.axios
        .get("/email-static-templates/" + this.$route.params.id)
        .then(function (response) {
          _vm.emailStaticTemplate = response.data.data;
        })
        .catch(function () { });
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
  },
};
